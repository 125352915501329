<template>
  <div class="typing-test">
    <h1 class="typing-iq">Typing IQ</h1>
    <p v-if="!isTestRunning && endTime" class="para">Your Result:</p>
    <div v-if="!isTestRunning && endTime" class="result">
      <div class="wpm">
        {{ wpm }} WPM
        <div style="font-size: small; color: white;">(words per minute)</div>
      </div>
      <p>
        <span class="result-label" style="text-align: left;">Keystrokes:</span>
        <span class="result-value" style="color: white; text-align: right;">
          <span style="color: #4CAF50;">{{ ' ' + correctKeystrokes }}</span> |
          <span style="color: #FF5252;">{{ wrongKeystrokes }}</span> |
          <span style="color: yellow;">{{ totalKeystrokes }}</span>
        </span>
      </p>
      <p>
        <span class="result-label" style="text-align: left;">Accuracy:</span>
        <span class="result-value" style="color: #4CAF50; text-align: right;">{{ calculateAccuracy() }}%</span>
      </p>
      <p>
        <span class="result-label" style="text-align: left;">Correct words:</span>
        <span class="result-value" style="color: #4CAF50; text-align: right;">{{ correctWords }}</span>
      </p>
      <p>
        <span class="result-label" style="text-align: left;">Wrong words:</span>
        <span class="result-value" style="color: #FF5252; text-align: right;">{{ wrongWords }}</span>
      </p>
    </div>
    <div class="type-para" v-else>
      <p class="typed-paragraph" v-html="(typingwrong) ? highlightedParagraphWrong : highlightedParagraph"></p>
    </div>
    <input v-if="!endTime" type="text" v-model="typedText" @keydown.space.prevent="checkInput()" @keyup="startTimer(), checkLetter()" class="type-word">
    <div class="timer-bar" :style="{ width: timerBarWidth, backgroundColor: timeBarColor }"></div>
    <div v-if="!endTime" class="time-left">
      <p class="time-label para">Time left:</p>
      <p class="time">{{ formattedTime }}</p>
    </div>
    <button @click="resetTest" class="refresh"><i class="fas fa-redo"></i> Reset</button>
  </div>
</template>

<script>
export default {
  name: 'TypingTest',
  data() {
    return {
      words: ['this', 'thing', 'sometimes', 'break', 'went', 'can\'t', 'been', 'question', 'quite', 'quit', 'from', 'form', 'forever', 'angry', 'limit', 'lucky', 'hours', 'records', 'oil', 'feed', 'won\'t', 'its', 'it\'s', 'old', 'many', 'place', 'time', 'experience', 'time', 'quest', 'just', 'computer', 'move', 'white', 'green', 'black', 'orange', 'different', 'hour', 'big', 'even', 'mountain', 'been', 'animal', 'long', 'after', 'page', 'tree', 'see', 'seen', 'right', 'left', 'has', 'went', 'above', 'said', 'good', 'long', 'it', 'why', 'that', 'learn', 'girl', 'boy', 'will', 'three', 'before', 'may', 'to', 'fine', 'flower', 'city', 'street'],
      shuffledWords: [],
      currentWordIndex: 0,
      typedText: '',
      timeLeft: 60,
      isTestRunning: false,
      typingwrong: false,
      highlightedParagraphWrong: null,
      timer: null,
      startTime: null,
      endTime: null,
      wpm: 0,
      accuracy: 0,
      correctWords: 0,
      wrongWords: 0,
      correctKeystrokes: 0,
      wrongKeystrokes: 0,
      totalKeystrokes: 0,
      wordStatus: [] // Track word status: '' for not typed, 'correct' for correctly typed, 'incorrect' for incorrectly typed
    };
  },
  created() {
    this.shuffleWords();
    this.wordStatus = Array(this.shuffledWords.length).fill('');
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    // Computed property for timer bar width
    timerBarWidth() {
      return `${(this.timeLeft / 60) * 70}%`; // Assuming the time is in seconds
    },
    timeBarColor() {
      if (this.timeLeft <= 20) {
        return 'red';
      } else if (this.timeLeft <= 40) {
        return 'orange';
      } else {
        return ''; // Default color
      }
    },
    highlightedParagraph() {
      const wordsPerLine = 10; // Number of words per line
      const lines = [];
      const startIndex = Math.floor(this.currentWordIndex / wordsPerLine) * wordsPerLine; // Calculate start index for the current line
      const endIndex = Math.min(startIndex + wordsPerLine * 2, this.shuffledWords.length); // Display two lines at a time
      let currentLine = [];
      for (let i = startIndex; i < endIndex; i++) {
        let wordClass = '';
        if (i === this.currentWordIndex) {
          wordClass = 'current-word';
        } else if (this.wordStatus[i] === 'correct') {
          wordClass = 'correct-word';
        } else if (this.wordStatus[i] === 'incorrect') {
          wordClass = 'wrong-word';
        }
        currentLine.push(`<span class="${wordClass}">${this.shuffledWords[i]}</span>`);
        if ((i + 1) % wordsPerLine === 0 || i === endIndex - 1) {
          lines.push(currentLine.join(' '));
          currentLine = [];
        }
      }
      return lines.join('<br>');
    }
  },
  methods: {
    shuffleWords() {
      this.shuffledWords = this.words.slice().sort(() => Math.random() - 0.5);
    },
    startTimer() {
      if (!this.timer) {
        this.startTime = new Date().getTime();
        this.timer = setInterval(this.updateTime, 1000);
      }
    },
    updateTime() {
      this.timeLeft--;
      if (this.timeLeft === 0) {
        clearInterval(this.timer);
        this.endTime = new Date().getTime();
        this.wpm = Math.round(this.correctKeystrokes / 5);
        this.accuracy = this.calculateAccuracy();
        this.isTestRunning = false;
      }
    },
    checkLetter() {
      const originalLetters = this.shuffledWords[this.currentWordIndex];
      const typedLetters = this.typedText.split('');
      const minLength = Math.min(originalLetters.length, typedLetters.length);
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.highlightedParagraph;

      // Get all spans with class 'current-word' and change their class to 'current-wrong'
      const currentWordSpans = tempElement.querySelectorAll('.current-word');
      
      for (let i = 0; i < minLength; i++) {
        if (originalLetters[i] !== typedLetters[i] || typedLetters.length > minLength) {
          this.typingwrong = true;
          currentWordSpans.forEach(span => {
            span.classList.add('current-wrong');
            span.classList.remove('current-word');
          });

          // Get the modified string
        } else {
          if(this.typedText.trim() === this.shuffledWords[this.currentWordIndex]){
            this.typingwrong = false;
          }
        }
      }
      this.highlightedParagraphWrong = tempElement.innerHTML;
    },
    checkInput() {
      this.typingwrong = false;
      const typedWord = this.typedText.trim();
      const currentWord = this.shuffledWords[this.currentWordIndex];
      // Check if typedWord matches the currentWord
      if(typedWord.length !== 0){
        if (typedWord === currentWord) {
          this.currentWordIndex++;
          this.typedText = ''; // Clear the input field after typing a correct word
          this.correctWords++;
          // Count the correct keystrokes only if the typed word is exactly equal to the current word
          this.correctKeystrokes += typedWord.length + 1;
          this.totalKeystrokes += typedWord.length + 1;
          this.wordStatus[this.currentWordIndex - 1] = 'correct';
        } else {
          // Move to the next word when space is pressed
          this.currentWordIndex++;
          this.typedText = ''; // Clear the input field after typing a space
          this.wrongWords++;
          this.wrongKeystrokes += typedWord.length + 1;
          this.totalKeystrokes += typedWord.length + 1;
          this.wordStatus[this.currentWordIndex - 1] = 'incorrect';
        }
      }
    },

    calculateAccuracy() {
      if (!this.totalKeystrokes) {
        return 0; // Or any default value you prefer
      }
      const accuracy = ((this.correctKeystrokes / this.totalKeystrokes) * 100).toFixed(2);
      return isNaN(accuracy) ? 0 : accuracy;
    },
    resetTest() {
      this.shuffleWords();
      clearInterval(this.timer);
      this.typingwrong = false;
      this.isTestRunning = false;
      this.currentWordIndex = 0;
      this.typedText = '';
      this.timeLeft = 60;
      this.timer = null;
      this.startTime = null;
      this.endTime = null;
      this.wpm = 0;
      this.accuracy = 0;
      this.correctWords = 0;
      this.wrongWords = 0;
      this.correctKeystrokes = 0;
      this.wrongKeystrokes = 0;
      this.totalKeystrokes = 0;
      this.wordStatus = Array(this.shuffledWords.length).fill('');
    }
  }
};
</script>

<style>
/* Add your CSS styling here */
/* Add your CSS styling here */

/* General styles */
body {
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
  background-color: #0d0818;
  color: #fff;
}

.wpm {
  font-size: 46px;
  color: #4caf50;
  font-weight: 800;
  display: block;
  margin-top: 3px;
  padding-bottom: 15px;
}

.typing-iq {
  font-size: 4em;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  text-shadow: 2px 2px 6px rgb(0 0 0 / 50%);
  background: linear-gradient(90deg, #051fb0, #6b0365);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInDown 1.5s ease-out, slideFromTop 2s ease-out;
}

.typing-test {
  text-align: center;
  margin-top: 50px;
  animation: slideInFromLeft 1s ease-in-out;
}

.type-para {
  width: 70%; /* Set the width */
  margin: 0 auto; /* Center the result section */
}

.result {
  margin-top: 20px;
  background: linear-gradient(to right, #23229b, #277eba);
  padding: 20px;
  border-radius: 10px;
  animation: fadeIn 1s ease-in-out;
  width: 20%;
  font-size: larger;
  margin: 0 auto; /* Center the result section */
}

.result p {
  margin: 5px 0;
  display: flex;
  justify-content: space-between; /* Align items horizontally with space between */
}

.result-label {
  font-weight: bold;
  color: white;
  text-align: left; /* Align text to the left */
}

.result-value {
  font-weight: bold;
  color: white;
  text-align: right; /* Align text to the right */
}

.current-word {
  background-color: #457093;
}

.current-wrong {
  background-color: #b13241;
}

.correct-word {
  color: #4CAF50;
}

.wrong-word {
  color: #FF5252;
}

.type-word {
  border: none;
  padding: 10px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 20px;
  width: 69%;
  font-weight: bold;
  color: #fff;
  margin-top: 0px;
  transition: background-color 0.3s;
}

.type-word:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.refresh {
  outline: none;
  border: none;
  background: linear-gradient(to right, #23229b, #277eba);
  width: 15%;
  font-size: 20px;
  margin-top: 20px;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.refresh:hover {
  background: linear-gradient(to right, #1a747a, #2ec5d0);
}

.typed-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 24px; /* bigger font size */
  font-weight: bold; /* specify font weight */
  border: 2px solid #55b1fc; /* add border */
  padding: 10px; /* add padding */
  margin: 20px 0; /* add margin */
  border-radius: 5px; /* add border radius */
  color: white;
}

.typed-paragraph span {
  transition: color 0.3s;
}

.typed-paragraph span.correct-word:hover {
  color: #4CAF50;
}

.typed-paragraph span.wrong-word:hover {
  color: #FF5252;
}

.typed-paragraph span.current-word {
  animation: blink-caret 0.75s step-end infinite;
}

.para {
  margin-top: 10px;
  font-weight: 900;
  margin-top: 10px;
  color: #55b1fc;
  animation: fadeIn 1s ease-in-out, slideFromBottom 1s ease-out;
}

/* Existing styles... */

.time-left {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideInFromBottom 1s ease-in-out;
}

.time-label {
  padding-top: 5px;
  font-weight: 800;
  color: #55b1fc;
  margin-right: 10px;
}

.time {
  font-weight: 800;
  font-size: 2em;
  color: #fff;
  animation: fadeIn 1s ease-in-out;
}

.timer-bar {
  height: 2px;
  background-color: #55b1fc;
  max-width: 70%; /* Set the maximum width to 70% */
  margin: auto;
  width: 100%;
  position: relative;
  border-radius: 5px;
}

.timer-bar::after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  animation: timerBarAnimation linear forwards;
}

@keyframes timerBarAnimation {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}


@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #fff;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media Queries for Phones */
@media only screen and (max-width: 600px) {
  /* Media Queries for Phones */
  @media only screen and (max-width: 600px) {
    .typing-test {
      margin-top: 20px; /* Adjust margin for smaller screens */
    }

    .type-word {
      width: 80%; /* Adjust input width for smaller screens */
      font-size: 16px; /* Adjust font size for smaller screens */
    }

    .type-para {
      width: 90%; /* Adjust width for smaller screens */
    }

    .result {
      width: 70%; /* Adjust width for smaller screens */
      font-size: small; /* Adjust font size for smaller screens */
    }

    .typed-paragraph {
      font-size: 20px; /* Adjust font size for smaller screens */
      border-width: 1px; /* Adjust border width for smaller screens */
      padding: 10px; /* Adjust padding for smaller screens */
      margin: 10px 0; /* Adjust margin for smaller screens */
      border-radius: 3px; /* Adjust border radius for smaller screens */
    }

    .refresh {
      width: 30%; /* Adjust width for reset button on mobile */
      font-size: 16px; /* Adjust font size for reset button on mobile */
      margin-top: 10px; /* Adjust margin top for reset button on mobile */
    }
  }
}

/* Media Queries for Tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .type-word {
    width: 75%; /* Adjust input width for tablets */
  }

  .type-para {
    width: 80%; /* Adjust width for tablets */
  }

  .result {
    width: 60%; /* Adjust width for tablets */
    font-size: medium; /* Adjust font size for tablets */
  }

  .typed-paragraph {
    font-size: 22px; /* Adjust font size for tablets */
  }
}

</style>
